.c-linesbg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;

  @include media("<=tablet") {
    svg {
      height: 100%;
    }
  }
}

.c-nav {
  display: flex;

  @include media(">tablet") {
    padding-left: toRem(126);
    padding-top: toRem(75);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1;
    display: block;
  }

  @include media("<=tablet") {
    justify-content: center;
    margin-top: toRem(142);
  }
}

.c-home {
  position: relative;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: space-between;
  padding: toRem(265) toRem(103);
  overflow: hidden;

  // &::selection,
  // *::selection {
  //   color: #c9ff00;
  // }

  @include media("<=tablet") {
    flex-direction: column;
    padding: toRem(67) toRem(56);
    overflow: unset;
  }

  @include media("<=phone") {
    flex-direction: column;
    padding: toRem(67) toRem(24);
    overflow: unset;
  }

  &__left {
    max-width: toRem(1064);
    width: 100%;
    display: inline-flex;
    flex-direction: column;

    @include media("<=tablet") {
      align-items: center;
      margin-bottom: toRem(56);
    }

    .c-notification {
      background: rgba(255, 255, 255, 0.05);
      backdrop-filter: blur(27.1846px);
      border-radius: 5px;
      display: inline-flex;
      align-items: center;
      padding: toRem(15) toRem(32);
      padding-right: toRem(8);
      margin-bottom: toRem(40);
      margin-left: toRem(16);
      width: fit-content;

      @include media("<=tablet") {
        // order: 1;
        // padding-top: toRem(6.19);
        // padding-left: toRem(21.99);
        // padding-bottom: toRem(16);
        // padding-right: (7.01);
        // transform: rotate(-31.58deg) translateX(toRem(-75.99));
        // position: relative;
        // margin-left: 0;
        display: none;
      }

      &__icon {
        margin-right: toRem(16);
      }

      &__title {
        font-size: toRem(15);
        line-height: calc(18 / 15);
        letter-spacing: toRem(0.24);
        margin-bottom: 4px;

        @include media("<=tablet") {
          font-size: toRem(10.7989);
        }
      }

      &__desc {
        font-size: toRem(12);
        line-height: calc(14 / 12);
        letter-spacing: toRem(0.2);
        color: #c2cee7;

        @include media("<=tablet") {
          font-size: toRem(8.6391);
        }
      }
    }
  }

  &__header {
    font-family: "Poppins", sans-serif;
    font-weight: 700;
    font-size: toRem(71.0649);
    line-height: 1.189;
    margin-bottom: toRem(44);
    margin-left: toRem(16);

    &::selection {
      color: #c9ff00;
    }

    @include media("<=tablet") {
      text-align: center;
      font-size: toRem(47.7551);
      margin-bottom: toRem(22);
      margin-left: 0;
    }

    @include media("<=phone") {
      font-size: 29.4289px;
    }
  }

  &__desc {
    max-width: toRem(564);

    @include media("<=tablet") {
      max-width: toRem(406.04);
    }

    @include media("<=phone") {
      text-align: center;
    }
  }

  &__descarea {
    display: flex;
    align-items: center;
    margin-bottom: toRem(52);

    @include media("<=tablet") {
      margin-bottom: toRem(48);
    }

    &__circularicon {
      max-width: 111.47px;
      width: 100%;
      margin-right: toRem(32);

      @include media("<=phone") {
        display: none;
      }

      svg {
        @extend %img;
      }
    }
  }

  &__form {
    width: 100%;
    margin-left: toRem(16);
    display: flex;

    @include media("<=tablet") {
      margin-bottom: toRem(100);
      margin-left: unset;
      justify-content: center;
    }

    input {
      width: 100%;
      max-width: toRem(499);
      height: toRem(56);
      background: rgba(255, 255, 255, 0.05);
      border: 0.583333px solid rgba(255, 255, 255, 0.1);
      box-sizing: border-box;
      border-radius: 5.83333px;
      font-family: "Open Sans", sans-serif;
      text-indent: toRem(20);
      font-size: toRem(16);
      margin-right: toRem(32);
      color: #a0aec0;

      @include placeholder {
        color: #a0aec0;
      }

      @include media("<=tablet") {
        height: toRem(40);
        max-width: toRem(359.24);
      }
    }

    button {
      background: #c9ff00;
      border-radius: 3.30192px;
      border: 0;
      font-weight: 600;
      font-family: "Open Sans", sans-serif;
      max-width: toRem(156);
      width: 100%;
      font-size: toRem(19.81);
      cursor: pointer;

      @include media("<=tablet") {
        font-size: toRem(14);
        max-width: toRem(112);
      }
    }
  }

  &__right {
    max-width: toRem(480);
    width: 100%;

    @include media("<=tablet") {
      display: none;
    }
  }

  &__exchange {
    margin-left: auto;
    max-width: toRem(312);
    position: relative;

    .c-bitcoin {
      max-width: toRem(74.01);
      width: 100%;
      @include ratio(1, 1);
      position: absolute;
      left: toRem(-250);
      top: toRem(-23);
    }

    .c-google {
      max-width: toRem(74.01);
      width: 100%;
      @include ratio(1, 1);
      position: absolute;
      left: toRem(-150);
      top: toRem(360);
    }

    .c-exchange {
      background: rgba(255, 255, 255, 0.05);
      border: toRem(0.833784) solid rgba(255, 255, 255, 0.1);
      box-sizing: border-box;
      border-radius: toRem(8.33784);
      width: toRem(312);
      height: toRem(67.54);
      display: flex;
      justify-content: space-between;

      @include media("<=tablet") {
        width: toRem(150.58);
        height: toRem(32.6);
        margin-bottom: 0;
        align-items: center;
      }

      &__left {
        margin-left: toRem(22);
        padding: toRem(14) 0;

        @include media("<=tablet") {
          padding: 0;
          margin-left: toRem(11);
        }
      }

      &__label {
        font-size: toRem(11.4146);
        line-height: toRem(14);
        letter-spacing: toRem(-0.163066);
        color: #a0aec0;

        @include media("<=tablet") {
          font-size: toRem(5.5);
          line-height: toRem(7);
        }
      }

      &__amount {
        font-size: toRem(19.0244);
        line-height: toRem(23);
        letter-spacing: toRem(-0.163066);

        @include media("<=tablet") {
          font-size: toRem(9.1819);
          line-height: toRem(11);
        }
      }

      &__right {
        max-width: toRem(141.73);
        width: 100%;
        background: rgba(255, 255, 255, 0.05);
        border: toRem(0.951219) solid rgba(255, 255, 255, 0.1);
        box-sizing: border-box;
        border-radius: toRem(9.51219);
        display: flex;
        padding-left: toRem(16.17);
        align-items: center;

        &__inner {
          display: flex;
          align-items: flex-start;
        }

        @include media("<=tablet") {
          max-width: toRem(68.41);
          padding-left: 0;
        }
      }

      &__flag {
        max-width: toRem(20);
        flex-grow: 0;
        display: flex;
        margin-right: toRem(12);

        img {
          @extend %img;
        }

        @include media("<=tablet") {
          max-width: toRem(9);
          margin-right: toRem(3);
        }
      }

      &__currency {
        line-height: 1;
        font-size: toRem(23.78);

        @include media("<=tablet") {
          font-size: toRem(11.4);
        }
      }

      &__shuffle {
        max-width: toRem(30.02);
        margin: toRem(12) auto;
        display: flex;
        align-items: center;

        img {
          @extend %img;
        }

        @include media("<=tablet") {
          max-width: toRem(14.49);
          margin: toRem(6) auto;
        }
      }
    }
  }

  &__cardsection {
    position: relative;
  }

  &__card {
    max-width: toRem(406.36);
    margin-left: auto;
    width: 100%;
    @include ratio(615.86, 406.36);
    position: absolute;
    right: 0;
    transform: translate(toRem(65), toRem(118.95));
  }
}

.response-message {
  margin-top: 20px;
  font-size: 14px;
  text-align: center;
  opacity: 0;
  transition: opacity 0.3s;

  &.visible {
    opacity: 1;
  }
}
