html {
  font-size: calc(100vw / 1728 * 10);

  @include media("<=tablet") {
    font-size: calc(100vw / 834 * 10);
  }

  @include media("<=phone") {
    font-size: calc(100vw / 428 * 10);
  }
}

// resets
body {
  font-family: "Open Sans", sans-serif;
  background: $licorice;
  min-height: 100vh;
  color: $white;
  font-size: toRem(24);
  line-height: calc(32 / 24);
  letter-spacing: toRem(0.18);
  // overflow: hidden;

  @include media("<=tablet") {
    font-size: toRem(17.2782);
  }
}
