@import "https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600&display=swap";
@import "https://fonts.googleapis.com/css2?family=Poppins:wght@700&display=swap";
.c-home__card img,
.c-home__card video, .c-home__exchange .c-exchange__shuffle img, .c-home__exchange .c-exchange__flag img, .c-home__exchange .c-google img,
.c-home__exchange .c-google video, .c-home__exchange .c-bitcoin img,
.c-home__exchange .c-bitcoin video, .c-home__descarea__circularicon svg {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
* {
  box-sizing: border-box;
}
*::before, *::after {
  box-sizing: border-box;
}
a {
  color: inherit;
  text-decoration: none;
}
html {
  font-size: 0.5787037037vw;
}
@media (max-width: 834px) {
  html {
    font-size: 1.1990407674vw;
  }
}
@media (max-width: 428px) {
  html {
    font-size: 2.3364485981vw;
  }
}
body {
  font-family: "Open Sans", sans-serif;
  background: #060809;
  min-height: 100vh;
  color: #ffffff;
  font-size: 2.4rem;
  line-height: 1.3333333333;
  letter-spacing: 0.018rem;
}
@media (max-width: 834px) {
  body {
    font-size: 1.72782rem;
  }
}
.c-linesbg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
@media (max-width: 834px) {
  .c-linesbg svg {
    height: 100%;
  }
}
.c-nav {
  display: flex;
}
@media (min-width: 835px) {
  .c-nav {
    padding-left: 12.6rem;
    padding-top: 7.5rem;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1;
    display: block;
  }
}
@media (max-width: 834px) {
  .c-nav {
    justify-content: center;
    margin-top: 14.2rem;
  }
}
.c-home {
  position: relative;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: space-between;
  padding: 26.5rem 10.3rem;
  overflow: hidden;
}
@media (max-width: 834px) {
  .c-home {
    flex-direction: column;
    padding: 6.7rem 5.6rem;
    overflow: unset;
  }
}
@media (max-width: 428px) {
  .c-home {
    flex-direction: column;
    padding: 6.7rem 2.4rem;
    overflow: unset;
  }
}
.c-home__left {
  max-width: 106.4rem;
  width: 100%;
  display: inline-flex;
  flex-direction: column;
}
@media (max-width: 834px) {
  .c-home__left {
    align-items: center;
    margin-bottom: 5.6rem;
  }
}
.c-home__left .c-notification {
  background: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(27.1846px);
  border-radius: 5px;
  display: inline-flex;
  align-items: center;
  padding: 1.5rem 3.2rem;
  padding-right: 0.8rem;
  margin-bottom: 4rem;
  margin-left: 1.6rem;
  width: fit-content;
}
@media (max-width: 834px) {
  .c-home__left .c-notification {
    display: none;
  }
}
.c-home__left .c-notification__icon {
  margin-right: 1.6rem;
}
.c-home__left .c-notification__title {
  font-size: 1.5rem;
  line-height: 1.2;
  letter-spacing: 0.024rem;
  margin-bottom: 4px;
}
@media (max-width: 834px) {
  .c-home__left .c-notification__title {
    font-size: 1.07989rem;
  }
}
.c-home__left .c-notification__desc {
  font-size: 1.2rem;
  line-height: 1.1666666667;
  letter-spacing: 0.02rem;
  color: #c2cee7;
}
@media (max-width: 834px) {
  .c-home__left .c-notification__desc {
    font-size: 0.86391rem;
  }
}
.c-home__header {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-size: 7.10649rem;
  line-height: 1.189;
  margin-bottom: 4.4rem;
  margin-left: 1.6rem;
}
.c-home__header::selection {
  color: #c9ff00;
}
@media (max-width: 834px) {
  .c-home__header {
    text-align: center;
    font-size: 4.77551rem;
    margin-bottom: 2.2rem;
    margin-left: 0;
  }
}
@media (max-width: 428px) {
  .c-home__header {
    font-size: 29.4289px;
  }
}
.c-home__desc {
  max-width: 56.4rem;
}
@media (max-width: 834px) {
  .c-home__desc {
    max-width: 40.604rem;
  }
}
@media (max-width: 428px) {
  .c-home__desc {
    text-align: center;
  }
}
.c-home__descarea {
  display: flex;
  align-items: center;
  margin-bottom: 5.2rem;
}
@media (max-width: 834px) {
  .c-home__descarea {
    margin-bottom: 4.8rem;
  }
}
.c-home__descarea__circularicon {
  max-width: 111.47px;
  width: 100%;
  margin-right: 3.2rem;
}
@media (max-width: 428px) {
  .c-home__descarea__circularicon {
    display: none;
  }
}
.c-home__form {
  width: 100%;
  margin-left: 1.6rem;
  display: flex;
}
@media (max-width: 834px) {
  .c-home__form {
    margin-bottom: 10rem;
    margin-left: unset;
    justify-content: center;
  }
}
.c-home__form input {
  width: 100%;
  max-width: 49.9rem;
  height: 5.6rem;
  background: rgba(255, 255, 255, 0.05);
  border: 0.583333px solid rgba(255, 255, 255, 0.1);
  box-sizing: border-box;
  border-radius: 5.83333px;
  font-family: "Open Sans", sans-serif;
  text-indent: 2rem;
  font-size: 1.6rem;
  margin-right: 3.2rem;
  color: #a0aec0;
}
.c-home__form input.placeholder {
  color: #a0aec0;
}
.c-home__form input::-webkit-input-placeholder {
  color: #a0aec0;
}
.c-home__form input::-moz-placeholder {
  color: #a0aec0;
}
.c-home__form input:-moz-placeholder {
  color: #a0aec0;
}
.c-home__form input:-ms-input-placeholder {
  color: #a0aec0;
}
@media (max-width: 834px) {
  .c-home__form input {
    height: 4rem;
    max-width: 35.924rem;
  }
}
.c-home__form button {
  background: #c9ff00;
  border-radius: 3.30192px;
  border: 0;
  font-weight: 600;
  font-family: "Open Sans", sans-serif;
  max-width: 15.6rem;
  width: 100%;
  font-size: 1.981rem;
  cursor: pointer;
}
@media (max-width: 834px) {
  .c-home__form button {
    font-size: 1.4rem;
    max-width: 11.2rem;
  }
}
.c-home__right {
  max-width: 48rem;
  width: 100%;
}
@media (max-width: 834px) {
  .c-home__right {
    display: none;
  }
}
.c-home__exchange {
  margin-left: auto;
  max-width: 31.2rem;
  position: relative;
}
.c-home__exchange .c-bitcoin {
  max-width: 7.401rem;
  width: 100%;
  font-size: 0;
  overflow: hidden;
  position: relative;
  aspect-ratio: 1;
  position: absolute;
  left: -25rem;
  top: -2.3rem;
}
@supports not (aspect-ratio: 1) {
  .c-home__exchange .c-bitcoin:after {
    content: "";
    display: inline-block;
    padding-top: 100%;
    width: 100%;
  }
}
.c-home__exchange .c-bitcoin img,
.c-home__exchange .c-bitcoin video {
  position: absolute;
  top: 0;
  left: 0;
}
.c-home__exchange .c-google {
  max-width: 7.401rem;
  width: 100%;
  font-size: 0;
  overflow: hidden;
  position: relative;
  aspect-ratio: 1;
  position: absolute;
  left: -15rem;
  top: 36rem;
}
@supports not (aspect-ratio: 1) {
  .c-home__exchange .c-google:after {
    content: "";
    display: inline-block;
    padding-top: 100%;
    width: 100%;
  }
}
.c-home__exchange .c-google img,
.c-home__exchange .c-google video {
  position: absolute;
  top: 0;
  left: 0;
}
.c-home__exchange .c-exchange {
  background: rgba(255, 255, 255, 0.05);
  border: 0.0833784rem solid rgba(255, 255, 255, 0.1);
  box-sizing: border-box;
  border-radius: 0.833784rem;
  width: 31.2rem;
  height: 6.754rem;
  display: flex;
  justify-content: space-between;
}
@media (max-width: 834px) {
  .c-home__exchange .c-exchange {
    width: 15.058rem;
    height: 3.26rem;
    margin-bottom: 0;
    align-items: center;
  }
}
.c-home__exchange .c-exchange__left {
  margin-left: 2.2rem;
  padding: 1.4rem 0;
}
@media (max-width: 834px) {
  .c-home__exchange .c-exchange__left {
    padding: 0;
    margin-left: 1.1rem;
  }
}
.c-home__exchange .c-exchange__label {
  font-size: 1.14146rem;
  line-height: 1.4rem;
  letter-spacing: -0.0163066rem;
  color: #a0aec0;
}
@media (max-width: 834px) {
  .c-home__exchange .c-exchange__label {
    font-size: 0.55rem;
    line-height: 0.7rem;
  }
}
.c-home__exchange .c-exchange__amount {
  font-size: 1.90244rem;
  line-height: 2.3rem;
  letter-spacing: -0.0163066rem;
}
@media (max-width: 834px) {
  .c-home__exchange .c-exchange__amount {
    font-size: 0.91819rem;
    line-height: 1.1rem;
  }
}
.c-home__exchange .c-exchange__right {
  max-width: 14.173rem;
  width: 100%;
  background: rgba(255, 255, 255, 0.05);
  border: 0.0951219rem solid rgba(255, 255, 255, 0.1);
  box-sizing: border-box;
  border-radius: 0.951219rem;
  display: flex;
  padding-left: 1.617rem;
  align-items: center;
}
.c-home__exchange .c-exchange__right__inner {
  display: flex;
  align-items: flex-start;
}
@media (max-width: 834px) {
  .c-home__exchange .c-exchange__right {
    max-width: 6.841rem;
    padding-left: 0;
  }
}
.c-home__exchange .c-exchange__flag {
  max-width: 2rem;
  flex-grow: 0;
  display: flex;
  margin-right: 1.2rem;
}
@media (max-width: 834px) {
  .c-home__exchange .c-exchange__flag {
    max-width: 0.9rem;
    margin-right: 0.3rem;
  }
}
.c-home__exchange .c-exchange__currency {
  line-height: 1;
  font-size: 2.378rem;
}
@media (max-width: 834px) {
  .c-home__exchange .c-exchange__currency {
    font-size: 1.14rem;
  }
}
.c-home__exchange .c-exchange__shuffle {
  max-width: 3.002rem;
  margin: 1.2rem auto;
  display: flex;
  align-items: center;
}
@media (max-width: 834px) {
  .c-home__exchange .c-exchange__shuffle {
    max-width: 1.449rem;
    margin: 0.6rem auto;
  }
}
.c-home__cardsection {
  position: relative;
}
.c-home__card {
  max-width: 40.636rem;
  margin-left: auto;
  width: 100%;
  font-size: 0;
  overflow: hidden;
  position: relative;
  aspect-ratio: 0.659825285;
  position: absolute;
  right: 0;
  transform: translate(6.5rem, 11.895rem);
}
@supports not (aspect-ratio: 0.659825285) {
  .c-home__card:after {
    content: "";
    display: inline-block;
    padding-top: 151.5552711881%;
    width: 100%;
  }
}
.c-home__card img,
.c-home__card video {
  position: absolute;
  top: 0;
  left: 0;
}
.response-message {
  margin-top: 20px;
  font-size: 14px;
  text-align: center;
  opacity: 0;
  transition: opacity 0.3s;
}
.response-message.visible {
  opacity: 1;
}
/*# sourceMappingURL=index.9ea482ad.css.map */
